@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.more {
    display: none;
}

input, select, textarea {
    width: 100%
} 

.logo {
    position: absolute;
    top:40px;
    left:8px;
}

@media screen and (max-width: 900px) {
    .logo {
        position: initial;
    }
}

.monstera-bg {
    background-image: url(assets/monstera_background.jpg);
}

html{
    @apply h-full
}

body{
    @apply h-full
}